import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResolverPageResolver } from '../resolver/resolver-page.resolver';

const routes: Routes = [
    {
        path: 'get-a-quote',
        loadChildren: () => import('./page-get-a-quote/page-get-a-quote.module').then(m => m.PageGetAQuoteModule),
        data: {
            path: "/get-a-quote"
        },
        resolve: { data: ResolverPageResolver }
    }, {
        path: 'get-a-quote/:device-type-slug',
        loadChildren: () => import('./page-get-a-quote/page-get-a-quote.module').then(m => m.PageGetAQuoteModule),
        data: {
            path: "/get-a-quote"
        },
        resolve: { data: ResolverPageResolver }
    }, {
        path: 'get-a-quote/:device-type-slug/:device-name-slug',
        loadChildren: () => import('./page-get-a-quote/page-get-a-quote.module').then(m => m.PageGetAQuoteModule),
        data: {
            path: "/get-a-quote"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'promotion/:device-type/:promotion-days',
        loadChildren: () => import('./page-sign-up/page-sign-up.module').then(m => m.PageSignUpModule),
        data: {
            path: "/promotion"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'promotion/:device-type/:promotion-days/:device-name',
        loadChildren: () => import('./page-sign-up/page-sign-up.module').then(m => m.PageSignUpModule),
        data: {
            path: "/promotion"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'internal-sign-up',
        loadChildren: () => import('./page-internal-sign-up/page-internal-sign-up.module').then(m => m.PageInternalSignUpModule),
        data: {
            path: "/internal-sign-up"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'instore',
        loadChildren: () => import('./page-internal-sign-up/page-internal-sign-up.module').then(m => m.PageInternalSignUpModule),
        data: {
            path: "/instore"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'about',
        loadChildren: () => import('./page-about/page-about.module').then(m => m.PageAboutModule),
        data: {
            path: "/about"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'about/:slug',
        loadChildren: () => import('./page-about/page-about.module').then(m => m.PageAboutModule),
        data: {
            path: "/about"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'news',
        loadChildren: () => import('./page-news/page-news.module').then(m => m.PageNewsModule),
        data: {
            path: "/news"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'news/:slug',
        loadChildren: () => import('./page-news/page-news.module').then(m => m.PageNewsModule),
        data: {
            path: "/news"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'news/read/:slug',
        loadChildren: () => import('./page-news-read/page-news-read.module').then(m => m.PageNewsReadModule),
        data: {
            path: "/news"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'news/:category',
        loadChildren: () => import('./page-news/page-news.module').then(m => m.PageNewsModule),
        data: {
            path: "/news"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'contact',
        loadChildren: () => import('./page-contact/page-contact.module').then(m => m.PageContactModule),
        data: {
            path: "/contact"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'contact/:slug',
        loadChildren: () => import('./page-contact/page-contact.module').then(m => m.PageContactModule),
        data: {
            path: "/contact"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'promotions',
        loadChildren: () => import('./page-promotion/page-promotion.module').then(m => m.PagePromotionModule),
        data: {
            path: "/promotions"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'promotions/:slug',
        loadChildren: () => import('./page-promotion/page-promotion.module').then(m => m.PagePromotionModule),
        data: {
            path: "/promotions"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'fequently-asked-questions/:slug',
        loadChildren: () => import('./page-fequently-asked-questions/page-fequently-asked-questions.module').then(m => m.PageFequentlyAskedQuestionsModule),
        data: {
            path: "/fequently-asked-questions"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'device/:insurance',
        loadChildren: () => import('./page-device-product/page-device-product.module').then(m => m.PageDeviceProductModule),
        data: {
            path: "/device"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'faq',
        loadChildren: () => import('./page-faq/page-faq.module').then(m => m.PageFaqModule),
        data: {
            path: "/faq"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: 'faq/:slug',
        loadChildren: () => import('./page-faq/page-faq.module').then(m => m.PageFaqModule),
        data: {
            path: "/faq"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: '',
        loadChildren: () => import('./page-index/page-index.module').then(m => m.PageIndexModule),
        data: {
            path: "/"
        },
        resolve: { data: ResolverPageResolver }
    },
    {
        path: ':provider-slug',
        loadChildren: () => import('./page-index/page-index.module').then(m => m.PageIndexModule),
        data: {
            path: "/"
        },
        resolve: { data: ResolverPageResolver }
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PageRoutingModule { }
