import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageRoutingModule } from './page-routing.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        PageRoutingModule,
    ]
})
export class PageModule { }
