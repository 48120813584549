import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  pageSlug: any = null

  decodeBase64(base64String: string): string {
    return atob(base64String);
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      switchMap(route => route.params)
    ).subscribe(params => {
      this.pageSlug = params['provider-slug'];
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap(() => this.activatedRoute.queryParams)
    ).subscribe(queryParams => {
      this.checkAndSaveUtmParams(queryParams);
    });
  }

  private checkAndSaveUtmParams(_queryParams: any) {
    const utmSource = _queryParams['utm_source'];
    const utmMedium = _queryParams['utm_medium'];
    const utmCampaign = _queryParams['utm_campaign'];
    const utmTerm = _queryParams['utm_term'];
    const ud = _queryParams['ud'];
  
    // if (utmSource || utmMedium || utmCampaign || utmTerm) {
    //   if (utmSource) localStorage.setItem('utm_source', utmSource);
    //   if (utmMedium) localStorage.setItem('utm_medium', utmMedium);
    //   if (utmCampaign) localStorage.setItem('utm_campaign', utmCampaign);
    //   if (utmTerm) localStorage.setItem('utm_term', utmTerm);
    //   localStorage.setItem('slug', this.pageSlug);
    // }
    if (utmSource) {
        const tmp_obj = {
          'utmSource': utmSource,
          'utmMedium': utmMedium,
          'utmCampaign': utmCampaign,
          'utmTerm': utmTerm,
          'ud': ud
        }
        localStorage.setItem('utmParams', JSON.stringify(tmp_obj))
        localStorage.setItem('slug', this.pageSlug);
      }
  }    
}
