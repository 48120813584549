import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { Apollo } from "apollo-angular";
import { Observable, retry } from "rxjs";
import { GQLService } from "src/app/service/gql.service";
import { GET_PAGE_BY_PATH } from "./query/page-query.gql";

@Injectable({
  providedIn: "root"
})
export class PageGql extends GQLService {
  constructor(protected override apollo: Apollo) {
    super(apollo);
  }

  findByPath(path: string): Observable<ApolloQueryResult<any>> {
    return this.watchQuery(GET_PAGE_BY_PATH, { path })
      .valueChanges.pipe(retry({ count: 3, delay: 3000 }));
  }
}