import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementHeroComponent } from './element-hero/element-hero.component';
import { ElementCarouselComponent } from './element-carousel/element-carousel.component';
import { ElementBannerComponent } from './element-banner/element-banner.component';
import { PipeModule } from '../pipe/pipe.module';
import { ElementProductCardComponent } from './element-product-card/element-product-card.component';
import { ElementTextComponent } from './element-text/element-text.component';
import { ElementProductGridComponent } from './element-product-grid/element-product-grid.component';
import { ElementSectionHeadingComponent } from './element-section-heading/element-section-heading.component';
import { ElementIconComponent } from './element-icon/element-icon.component';
import { ElementIconGroupComponent } from './element-icon-group/element-icon-group.component';
import { ElementTextGroupComponent } from './element-text-group/element-text-group.component';
import { ElementParagraphGroupComponent } from './element-paragraph-group/element-paragraph-group.component';
import { ElementParagraphComponent } from './element-paragraph/element-paragraph.component';
import { ElementProductListComponent } from './element-product-list/element-product-list.component';
import { ElementProductListPromoComponent } from './element-product-list-promo/element-product-list-promo.component';
import { ElementPromoCardComponent } from './element-promo-card/element-promo-card.component';
import { ElementListComponent } from './element-list/element-list.component';
import { ElementArticleComponent } from './element-article/element-article.component';
import { ElementFooterComponent } from './element-footer/element-footer.component';
import { ElementNavComponent } from './element-nav/element-nav.component';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from '../directive/directive.module';
import { ElementProductCardHeroComponent } from './element-product-card-hero/element-product-card-hero.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ElementHeroComponent,
    ElementCarouselComponent,
    ElementBannerComponent,
    ElementProductCardComponent,
    ElementTextComponent,
    ElementProductGridComponent,
    ElementPromoCardComponent,
    ElementSectionHeadingComponent,
    ElementIconComponent,
    ElementIconGroupComponent,
    ElementTextGroupComponent,
    ElementParagraphGroupComponent,
    ElementParagraphComponent,
    ElementProductListComponent,
    ElementProductListPromoComponent,
    ElementListComponent,
    ElementArticleComponent,
    ElementFooterComponent,
    ElementNavComponent,
    ElementProductCardHeroComponent
  ],
  imports: [
    CommonModule,
    PipeModule,
    RouterModule,
    DirectiveModule,
    ReactiveFormsModule
  ],
  exports: [
    ElementHeroComponent,
    ElementCarouselComponent,
    ElementProductCardComponent,
    ElementBannerComponent,
    ElementTextComponent,
    ElementProductGridComponent,
    ElementPromoCardComponent,
    ElementSectionHeadingComponent,
    ElementIconComponent,
    ElementIconGroupComponent,
    ElementTextGroupComponent,
    ElementParagraphGroupComponent,
    ElementParagraphComponent,
    ElementProductListComponent,
    ElementProductListPromoComponent,
    ElementListComponent,
    ElementArticleComponent,
    ElementFooterComponent,
    ElementNavComponent,
    ElementProductCardHeroComponent
  ]
})
export class ElementModule { }
