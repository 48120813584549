import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmphasisWordDirective } from './emphasis-word.directive';



@NgModule({
  declarations: [EmphasisWordDirective],
  imports: [
    CommonModule
  ],
  exports:[
    EmphasisWordDirective
  ]
})
export class DirectiveModule { }
