import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeAttributePipe } from './pipe-attribute.pipe';
import { PipeFindPipe } from './pipe-find.pipe';
import { PipeSplitPipe } from './pipe-split.pipe';
@NgModule({
  declarations: [
    PipeAttributePipe,
    PipeFindPipe,
    PipeSplitPipe
  ],
  imports: [
    CommonModule
  ],exports:[
    PipeAttributePipe,
    PipeFindPipe,
    PipeSplitPipe
  ]
})
export class PipeModule { }
