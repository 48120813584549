import { gql } from "@apollo/client/core";

export const GET_PAGE_BY_PATH = gql`
query GetPageByPath($path: String!) {
  pages(filters:{path:{eq:$path}}){
    data{
        attributes{
            title
            path
            seo{
                canonicalURL
                keywords
                metaDescription
                metaImage{
                    data{
                        attributes{
                            url
                            name
                            alternativeText
                        }
                    }
                }
                metaRobots
                metaSocial{
                    description
                    image{
                        data{
                            attributes{
                                url
                                name
                                alternativeText
                            }
                        }
                    }
                    socialNetwork
                    title
                }
                metaTitle
                metaViewport
                structuredData             
            }            
            element{
                __typename

                ... on ComponentElementProductCardHero{
                    identifier
                    buttons{
                        route
                        text
                        type
                    }
                    previewImage: image{
                        data{
                            attributes{
                            
                                alternativeText
                                url
                                height
                                width
                            }
                        }
                    }
                    product{
                        data{
                            attributes{
                                name
                                price
                                product_id
                                description
                                features{
                                    data{
                                        attributes{
                                            name
                                            text
                                        }
                                    }
                                }
                                category{
                                    data{
                                        attributes{
                                            name
                                            description
                                            slug
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
                
                ... on ComponentElementCategory{
                    identifier
                    categories{
                        data{
                            attributes{
                                name
                                description
                                slug
                                parent{
                                    data{
                                        attributes{
                                            name
                                            slug
                                            description
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ... on ComponentElementProductList{
                    identifier
                    products{
                        data{
                            attributes{
                                features{
                                    data{
                                        attributes{
                                            name
                                            text
                                        }
                                    }
                                }
                                name
                                price
                                slug
                                product_id
                                category{
                                    data{
                                        attributes{
                                            name
                                            slug
                                            description
                                        }
                                    }
                                }
                                thumbnail{
                                    data{
                                        attributes{
                                            alternativeText
                                            url
                                            name
                                            height
                                            width
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
                ... on ComponentElementParagraphGroup{
                    identifier
                    paragraphs{
                        identifier
                        text
                    }    
                }
                ... on ComponentElementParagraph{
                    elementIdentifier: identifier
                    text
                }                

                ... on ComponentElementTextGroup{
                    identifier
                    textItems{
                        element
                        emphasis
                        emphasisClass
                        text
                    }
                }

                  ... on ComponentElementText{
                        element
                        emphasis
                        emphasisClass
                        textElementIdentifier: identifier
                        text
                }

                ... on ComponentElementIconGroup{
                    identifier
                    icons{
                        heading
                        image{
                            data{
                                attributes{
                                    alternativeText
                                    url
                                    height
                                    width
                                }
                            }
                        }
                        subHeading
                    }
                }

                ... on ComponentElementBanner{
                    identifier
                    bannerHeading: heading
                    subHeading
                    buttonLabel
                    buttonRoute
                    image{
                        data{
                            attributes{
                                url
                                height
                                width
                                alternativeText
                            }
                        }
                    }
                }

                ... on ComponentElementSectionHeading{
                    sectionHeading: heading
                    identifier
                    subHeading
                }

                ... on ComponentElementProductGrid{
                     component{
                         data{
                             attributes{
                                 griditems{
                                     buttons{
                                         text
                                         route
                                         type
                                     }
                                     product{
                                         data{
                                             attributes{
                                                    features{
                                                        data{
                                                            attributes{
                                                                name
                                                                text
                                                            }
                                                        }
                                                    }                                                 
                                                 name
                                                 price
                                                 description
                                                 product_id
                                                 slug
                                                 
                                             }
                                         }
                                     }
                                     image{
                                         data{
                                             attributes{
                                                 url
                                                 alternativeText
                                                 caption
                                                 width
                                                 height
                                             }
                                         }
                                     }
                                 }
                             }
                         }
                     }
                }
                
                ... on ComponentElementCarousel{    
                    component{
                        data{
                            attributes{
                                identifier
                                items{
                                    __typename

                                    ... on ComponentElementCarouselItem{
                                        image{
                                            source{
                                                data{
                                                    attributes{
                                                        url
                                                        alternativeText
                                                        caption
                                                        width
                                                        height
                                                    }
                                                }
                                            }
                                        }
                                        captions{
                                            element
                                            text
                                            emphasis
                                            emphasisClass
                                        }
                                        button{
                                            text
                                            type
                                            route
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                ...on ComponentWidgetFrequentlyAskedQuestion{
                    categories{
                        data{
                            attributes{
                                slug
                                name
                            }
                        }
                    }
                }

                ...on ComponentWidgetArticle{
                    article{
                        data{
                            attributes{

                                image{
                                    data{
                                        attributes{
                                            url
                                            alternativeText
                                            height
                                            width
                                        }
                                    }
                                }
                                title
                                summary
                                slug
                                updatedAt
                                author{
                                    data{
                                        attributes{
                                            biography
                                            firstName
                                            lastName

                                        }
                                    }
                                }

                elements{
                    ... on ComponentElementParagraphGroup{
                        identifier
                        paragraphs{
                            identifier
                            text
                        }    
                    }
                    ... on ComponentElementParagraph{
                        elementIdentifier: identifier
                        text
                    }                

                    ... on ComponentElementTextGroup{
                        identifier
                        textItems{
                            element
                            emphasis
                            emphasisClass
                            text
                        }
                    }

                    ... on ComponentElementText{
                            element
                            emphasis
                            emphasisClass
                            textElementIdentifier: identifier
                            text
                    }     

                    ... on ComponentElementList{
                        identifier
                        items{
                            text
                        }
                    }               
                }

                            }
                        }
                    }
                }
            }
        }
    }
  }
}
`;