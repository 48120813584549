import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { PageGql } from '../gql/page/page.gql';
import { ApolloQueryResult } from '@apollo/client/core';
import * as _ from 'lodash';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ResolverPageResolver implements Resolve<ApolloQueryResult<any>> {

  constructor(protected readonly pageGQL: PageGql) {

  }

  resolve: ResolveFn<ApolloQueryResult<any>> = (activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ApolloQueryResult<any>>  => {
    console.debug("resolver page data ", activatedRouteSnapshot.data);
    return this.pageGQL.findByPath(_.get(activatedRouteSnapshot.data, "path")).pipe(
      map(({loading,data}) => {
        if( !loading )
          return data;
      })
    );;
    // return of(true);
  }
}
